import { useLocation } from "react-router";
import type { ReactNode } from "react";
import { Fragment, createContext, useContext, useEffect, useRef, useState } from "react";
import { DialogTrigger } from "react-aria-components";

export type ClientSession = {
  [name: string]: any;
};

export type DialogContextType = (dialog: (closeDialog: () => void) => JSX.Element) => void;

export const DialogContext = createContext<DialogContextType>(() => () => {});

/**
 * Provides a new dialog context.
 * @example
 * let dialog = useShowDialog();
 */
export function DialogProvider({ children }: { children: ReactNode }) {
  const nextId = useRef(0);
  const location = useLocation();
  const [dialogs, setDialogs] = useState<Array<JSX.Element>>([]);
  useEffect(() => {
    setDialogs(() => {
      return [];
    });
  }, [location]);
  const showDialog = (dialog: (closeDialog: () => void) => JSX.Element) => {
    const id = nextId.current++;
    const closeDialog = () =>
      setDialogs((value) => {
        return [...value.filter((x) => x.key != id.toString())];
      });
    const Dialog = dialog(closeDialog);
    setDialogs(() => {
      return [
        <DialogTrigger defaultOpen={true} key={id}>
          {Dialog}
        </DialogTrigger>,
      ];
    });
    return closeDialog;
  };

  return (
    <DialogContext.Provider value={showDialog}>
      {dialogs}
      {children}
    </DialogContext.Provider>
  );
}

/**
 * Returns a dialog instance.
 * @example
 * const showDialog = useShowDialog();
 * const closeHandle = showDialog({...});
 * closeHandle();
 */
export function useShowDialog() {
  return useContext(DialogContext);
}
